export const mapObject = (obj, other) => {
  for (let key in obj) {
    obj[key] = other.hasOwnProperty(key) ? other[key] : obj[key];
  }
};

export const renderFunnelType = (funnelType) => {
  switch (funnelType) {
    case "master":
      return <span className="master-class">1 Hour Masterclass</span>;
    case "live_opportunity_call":
      return <span className="live-call-class">Live Opportunity Call</span>;
    default:
      return null;
  }
};

export const convertTimeTo12HourFormat = (time) => {
  const [hours, minutes, seconds] = time.split(":");
  const date = new Date();
  date.setHours(
    parseInt(hours),
    parseInt(minutes || 0),
    parseInt(seconds || 0)
  );

  const options = { hour: "numeric", minute: "numeric", hour12: true };
  return date.toLocaleTimeString(undefined, options);
};

export const convertToDate = (value) => {
  if (!value) return "";
  const myArray = value.split(" ");
  return myArray[0] ?? "";
};

export const convertToTime = (value) => {
  if (!value) return "";
  const myArray = value.split(" ");
  return myArray[1] ?? "";
};

export const convertDateTimeString = (inputDateTimeString) => {
  const inputDateTime = new Date(inputDateTimeString);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const formattedDate = `${
    months[inputDateTime.getMonth()]
  } ${inputDateTime.getDate()}, ${inputDateTime.getFullYear()}`;

  let hours = inputDateTime.getHours();
  const minutes =
    inputDateTime.getMinutes() < 10
      ? `0${inputDateTime.getMinutes()}`
      : inputDateTime.getMinutes();
  const AmPm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12;

  const formattedTime = `${hours}:${minutes} ${AmPm}`;
  const formattedDateTime = `${formattedDate} ${formattedTime}`;

  return formattedDateTime;
};

export const getRemainingTimeUntilLive = (liveAt, liveTimezone) => {
  if (liveAt === "" || liveTimezone === "") {
    return "";
  }
  const currentDate = new Date();
  let timeString;
  switch (liveTimezone) {
    case "PST":
      const pstOffset = 0.0001 * 60;
      const pstTimestamp = new Date(
        currentDate.getTime() + pstOffset * 60 * 1000
      );
      timeString = pstTimestamp.toISOString().replace("T", " ").substr(0, 16);
      break;
    // case "MST":
    //   const mstOffset = -7 * 60;
    //   const mstTimestamp = new Date(
    //     currentDate.getTime() + mstOffset * 60 * 1000
    //   );
    //   timeString = mstTimestamp.toISOString().replace("T", " ").substr(0, 16);
    //   break;
    // case "EST":
    //   const estOffset = -5 * 60;
    //   const estTimestamp = new Date(
    //     currentDate.getTime() + estOffset * 60 * 1000
    //   );
    //   timeString = estTimestamp.toISOString().replace("T", " ").substr(0, 16);
    //   break;
    // case "CST":
    //   const cstOffset = -6 * 60;
    //   const cstTimestamp = new Date(
    //     currentDate.getTime() + cstOffset * 60 * 1000
    //   );
    //   timeString = cstTimestamp.toISOString().replace("T", " ").substr(0, 16);
    //   break;
    default:
      break;
  }

  const timestamp1 = new Date(liveAt);
  const timestamp2 = new Date(timeString);

  const timeDifference = timestamp1 - timestamp2;

  if (timeDifference < 0) {
    return "Times up, waiting for the next live call";
  }

  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

  return `Training will go live in: ${days} days, ${hours} hours, ${minutes} minutes`;
};
export const getDocumentTitle = (location) => {
  const currentPaths = location.pathname.split("/");
  const currentLocation = currentPaths.pop();
  const TITLE = (
    currentLocation.charAt(0).toUpperCase() + currentLocation.slice(1)
  ).replace("-", " ");
  if (currentPaths.includes("admin")) {
    return "Courses";
  } else if (currentPaths.includes("profile")) {
    return "Profile";
  } else {
    return TITLE;
  }
};
