import {fetchClientSecretToken} from "../api/SubscriptionApi";
import {fetchReferral} from "../api/LeadApi";
import {fetchQuestions} from "../api/QuestionnaireApi";
import {shortTermAuth} from "../api/AuthApi";
import {getAllCourses, fetchCoursesCategories, getCourseLessons} from "../api/CoursesApi";
import {fetchAdminCourses, fetchAdminRoles, fetchAdminCourse} from "../api/AdminCoursesAPI";
import { getMember } from "../api/ProfileApi";

export async function loadClientSecret() {
  const clientSecretResponse = await fetchClientSecretToken();
  if (!clientSecretResponse) throw new Response("Not Found: 404", {status: 404});
  return clientSecretResponse.data.client_secret;
}

export async function loadReferral({request}) {
  const url = new URL(request.url);
  const referralID = url.searchParams.get("referral") ?? null;

  const referral = await fetchReferral(referralID);
  if (!referral) throw new Response("Not Found: 404", {status: 404});
  return referral.data;
}

export async function loadQuestions({request, params}) {
  const userId = params.user_id;
  const questions = await fetchQuestions(userId);
  if (!questions) throw new Response("Not Found: 404", {status: 404});
  return questions.data;
}

export async function userTempToken({request, params}) {
  try {
    const url = new URL(request.url);
    const calendlyUrl = url.searchParams.get("calendly_url");

    const userId = params.userId;
    const temptoken = await shortTermAuth(userId);

    return {
      token: temptoken.data,
      calendly_url: calendlyUrl,
    };
  } catch {
    throw new Response("Something wrong with user identifier", {status: 404});
  }
}

export async function loadAllCourses({request, params}) {
  const url = new URL(request.url);
  const category_uuid = params.categoryId;
  const page = url.searchParams.get("page") ?? null;

  const response = await getAllCourses(category_uuid, page);
  if (!response) throw new Response("Not Found: 404", {status: 404});
  return {
    coursesData: response.data,
    category_uuid: category_uuid
  }
}

export async function loadCoursesCategories() {
  const response = await fetchCoursesCategories();
  if (!response) throw new Response("Not Found: 404", {status: 404});
  return response.data;
}

export async function loadCourseLessons({request, params}) {
  const course_uuid = params.courseId;
  const response = await getCourseLessons(course_uuid);
  if (!response) throw new Response("Not Found: 404", {status: 404});
  return response.data;
}

export async function loadAdminCourses() {
  try {
    const [Courses, Roles] = await Promise.all([fetchAdminCourses(), fetchAdminRoles()]);
    const fetchedCourses = Courses.data;
    const fetchedRoles = Roles.data;
    return {fetchedCourses, fetchedRoles};
  } catch(error) {
    throw new Response(`${error.statusText}: ${error.status}`, {status: error.status});
  }
}

export async function loadAdminCourse({params}) {
  try {
    const courseId = params.courseId;
    const [Course, Roles] = await Promise.all([fetchAdminCourse(courseId), fetchAdminRoles()]);
    const fetchedCourse = Course.data;
    const fetchedRoles = Roles.data;
    return {fetchedCourse, fetchedRoles};
  } catch(error) {
    throw new Response(`${error.statusText}: ${error.status}`, {status: error.status});
  }
}

export async function loadUserInfo({params}) {
  try {
    const userId = params.userId;
    const userInfo = await getMember({ userId });
    const memberData = userInfo.data.user;
    return {memberData};
}
  catch {
    throw new Response("Not Found: 404", {status: 404});
  }
}