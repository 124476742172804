import axios from "axios";

const UPDATEadministrator_PAYLOAD = {
  user_uuid: null,
  advisor_uuid: null,
  affiliate_uuid: null,
};
const UPDATEroles_PAYLOAD = {
  user_uuid: null,
  role_uuid: null,
};

const UpdatePasswordData = {
  old_password: null,
  password: null,
  password_confirmation: null,
};

const UpdateUserInfoData = {
  name: null,
  instagram: null,
  phone: null,
  phone_number: null,
  country_code: null,
  country_iso_name: null,
  paypal: null,
};

const UpdateAdvisorSettingsData = {
  scheduling_link: null,
  facebook_link: null,
  advisor_message: null,
};

const UpdateNotificationData = {
  notification_key: null,
  notification_value: null,
};

const USERSLIST_PAYLOAD = {
  query: null,
  page: null,
  filter_advisors: null
};

export async function getMember(payload = { userId: null }) {
  try {
    const response = await axios.get(`/user/${payload.userId}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

export async function getUsersList(payload = USERSLIST_PAYLOAD) {
  return await axios
    .get("/users", { params: payload })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function updateAdministrator(payload = UPDATEadministrator_PAYLOAD) {
  return await axios
    .post("/update-administrator", payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}
export async function updateRoles(payload = UPDATEroles_PAYLOAD) {
  return await axios
    .post("/assign-role", payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function updatePassword(payload = UpdatePasswordData) {
  return await axios
    .post("/profile/update-password", payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function updateUserInfo(payload = UpdateUserInfoData) {
  return await axios
    .post("/profile/update-info", payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function updateAdvisorSettings(payload = UpdateAdvisorSettingsData) {
  return await axios
    .post("/profile/update-advisor-settings", payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function updateNotifications(payload = UpdateNotificationData) {
  return await axios
    .post("/profile/update-notification", payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}
